import { Injectable } from '@angular/core';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { MatBottomSheet, MatDialog } from '@angular/material';
import { identifierModuleUrl } from '@angular/compiler';

@Injectable({
    providedIn: 'root'
})

export class PopupService {

    constructor(private dialog: MatDialog, private breakpointObserver: BreakpointObserver,private _bottomSheet:MatBottomSheet) { }

    private matDialogRef: any;
    private smallDialogSubscription: any;

    open({componentOrTemplateRef, width = '500px', height = 'auto'}) {
        this.close();
        this.matDialogRef = this.dialog.open(componentOrTemplateRef, {
            maxHeight:'100vh',
            maxWidth:'100vw',            
        });

        this.smallDialogSubscription = this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
            .subscribe(size => {
                if (size.matches) {
                    this.matDialogRef.updateSize('100%', '100%');
                } else {
                    this.matDialogRef.updateSize(width, height);
                }
            });

        return this.matDialogRef;
    }

    close(): void {
        if(this.smallDialogSubscription){
            this.smallDialogSubscription.unsubscribe();
        }
        if(this.matDialogRef){
            this.matDialogRef.close();
        }
    }

    openSheet(content){
        this._bottomSheet.open(content)
    }

    closeSheet(){
        this._bottomSheet.dismiss()
    }

}