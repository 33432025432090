import { Injectable } from "@angular/core";
import { APP_CONSTANT } from "../config/app.config";
import { ConfigService } from "./common/config.service";
import { LocationService } from "./common/location.service";

@Injectable({ providedIn: 'root' })
export class InitAppService {

    constructor(private _locationService:LocationService,private configService:ConfigService,){}

    _initApp(){

        return new Promise(async (resolve,rejects)=>{
            console.log('App Initialization Start....')
            try{
                await this.configService.config();
                await this._locationService.set_current_location()
                var string_file = JSON.parse(this.configService.subadmin.string_file)
                
                if(Object.keys(string_file).length){
                    Object.keys(string_file).forEach((_key)=>{
                        if(string_file[_key].toString().includes('[')){
                            APP_CONSTANT[_key] = string_file[_key].replace('[','').replace(']','').replaceAll("'",'').split(',')
                        }else{
                            APP_CONSTANT[_key] = string_file[_key]
                        }                        
                    })
                }
                // console.log(APP_CONSTANT);
                console.log('App Initialization End....')
                resolve(true);
            }catch(err){
                console.log('error');
                rejects(true);
            }
        })
    }

    
    

}
