export class Customer {
    id:Number = 0;
    fullname:string = '';
    username:string = '';
    email:string = '';
    companyname:string = '';
    alternative_email:string = '';
    alternative_contact:string = '';
    website_url:string = '';
    profession:string = '';
    terms_to_buy:any = '';
    terms_to_sell:any = '';
    address:{
        address_line1:string,
        address_line2:string,
        city:string,
        state:string,
        pincode:string        
    };
    avtar:any = '';
    balance:Number = 0;
    participated_enquiries: Number = 0;
    country:string = '';
    countryid:string = '';
    currency:string = '';
    currency_sign:string = '';
    is_profile_locked:boolean = false;
    is_verified: boolean = false;
    is_balance_pending: boolean = false;
    is_pending_credit: boolean = false;
    transaction_type:string = '';
    tags:Array<string> = [];
}
