import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse }   from '@angular/common/http';
import { Injectable } from "@angular/core"
import { MatSnackBar } from '@angular/material';
import { Observable, of } from "rxjs";
import { tap, catchError } from "rxjs/operators";

@Injectable()
export class ResponseHttpInterceptor implements HttpInterceptor {
    constructor(private _snackBar: MatSnackBar) {}
intercept(
        req: HttpRequest<any>,
        next: HttpHandler
      ): Observable<HttpEvent<any>> {                

        return next.handle(req).pipe(
            tap(evt => {
                if (evt instanceof HttpResponse) {
                    if(evt.body && evt.body.success){
                        if(evt.body.message){
                            this._snackBar.open(evt.body.message, null, {duration: 3000,horizontalPosition: 'center',verticalPosition: 'bottom',panelClass: ['mat-success']});
                        }
                    }else{
                        if(evt.body.message){
                            this._snackBar.open(evt.body.message, null, {duration: 3000,horizontalPosition: 'center',verticalPosition: 'bottom',panelClass: ['mat-danger']});
                        }
                    }
                }
            }),
            catchError((err: any) => {
                if(err instanceof HttpErrorResponse) {
                    console.log(err.message)                    
                }
                return of(err);
            }));
    
      }
      
}