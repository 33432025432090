import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SearchpipePipe } from '../pipes/searchpipe.pipe';
import { ShortenPipe } from '../pipes/shorten.pipe';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomerHideIfUnauthorizedDirective } from '../directive/customer-hide-if-unauthorized.directive'
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { AngularMaterialModule } from './material.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatSelectSearchModule } from '../components/mat-search-select/mat-search-select.module';
import { RoundingPipe } from '../pipes/rounding.pipe';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { TranslatePipe } from '../pipes/translate.pipe';

@NgModule({
  declarations: [SearchpipePipe,RoundingPipe,ShortenPipe,CustomerHideIfUnauthorizedDirective,TranslatePipe],
  exports: [
    FormsModule,
    NgxSkeletonLoaderModule,
    NgbPaginationModule,
    HttpClientModule,
    SearchpipePipe,
    RoundingPipe,
    TranslatePipe,
    LazyLoadImageModule,
    OwlDateTimeModule,
    ShortenPipe,
    AngularMaterialModule,
    NgSelectModule,
    NgOptionHighlightModule,
    CustomerHideIfUnauthorizedDirective,
    OwlNativeDateTimeModule,
    ReactiveFormsModule,
    MatSelectSearchModule
  ]
})
export class SharedModule { }
