import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {

  loading: boolean = false;
  loadingSubscription: Subscription;

  constructor(private _helperService:HelperService) { }

  ngOnInit() {
    this.loadingSubscription = this._helperService.loadingObservable.subscribe(value => {
      this.loading = value;
    });
    // this.loadingSubscription = this._helperService.loadingStatus.pipe(debounceTime(200)).subscribe((value) => {
    //   this.loading = value;
    // });
  }

  ngOnDestroy() {
    this.loadingSubscription.unsubscribe();
  }

}
