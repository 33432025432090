export class APP_CONFIG {
 public APP_NAME = 'krishitrade'
 public APP_DESCRIPTION = 'KrishiTrade is post-harvest buying & selling of agricultural produce on enquiries & quotations platform with integrated supply chain solution. Krishi Trade also has e-commerce cart for selling of products related to farm equipment’s& consumables.'
 public APP_URL = 'https://krishitrade.com'
 public API_URL = 'https://api.krishitrade.com'
 public IMAGE_URL = 'https://api.krishitrade.com'
 public DEFAULT_TAX = 2
}

export class IMAGE_FOLDER {
    public PARTNER = '/partners/'    
    public ENQUIRY = '/enquiries/'    
    public BLOGS = '/blogs/'   
    public SUBCATEGORY = '/subcategories/'
    public CATEGORY = '/categories/'
}

export const DEFAULT_IMAGE = {
    LOGO:'assets/img/brand/favicon.jpg',
    UPLOAD:'assets/images/default_images/upload.jpeg',

}

export enum SYSTEM_MODE {TRADE = 1,STRICT = 2}
export var USER_PROFILE_TYPE  = {TRADER:1,BUYER:2,SELLER:3}


export const APP_CONSTANT = {
    TXT_BUY: "Buy",
    TXT_SELL: "Sell",
    BTN_CREATE_BUY: "New Buy Enquiry",
    BTN_CREATE_SELL: "New Sell Enquiry",
    TXT_ID_BUY: "Enquiry No",
    TXT_ID_SELL: "Enquiry No",
    TXT_QUANTITY_BUY: "Quantity",
    TXT_QUANTITY_SELL: "Stock",
    TXT_SUBCATEGORY: "Product / Produce",
    TXT_QUOTE_TO_BUY: "Quote to Buy",
    TXT_QUOTE_TO_SELL: "Quote to Sell",
    TXT_CLICK_TO_BUY: "Click to Buy",
    TXT_CLICK_TO_SELL: "Click to Sell",
    TXT_ENQ_COMMENT_BUY: "Variety / Packaging / Commercial",
    TXT_ENQ_COMMENT_SELL: "Variety / Packaging / Commercial",
    TXT_ENQ_QUALITY_BUY: "Quality",
    TXT_ENQ_QUALITY_SELL: "Product Availability",
    TXT_ENQ_WEIGHT_BUY: "Quantity Available / Serviceable",
    TXT_ENQ_WEIGHT_SELL: "Quantity Available / Serviceable",
    TXT_ENQ_SOURCE_BUY: "Preferred Location to Buy",
    TXT_ENQ_SOURCE_SELL: "Available At Location",
    TXT_ENQ_DESTINATION_BUY: "Place of Receipt by Buyer",
    TXT_ENQ_DESTINATION_SELL: "Preferred Delivery Location",
    TXT_ENQ_ESTIMATE_PRICE_BUY: "Estimate Price",
    TXT_ENQ_ESTIMATE_PRICE_SELL: "Offered Price",
    TXT_PAY_IN_BUY: "Buyer will Pay in",
    TXT_PAY_IN_SELL: "Seller Preferred Currency",
    TXT_BUYER:"Buyer",
    CATEGORY:"Category",
    TXT_SELLER:"Seller",
    TXT_PRICE:"Price",
    QUALITIES : ['Export','Regular','Mall','Organic','Certified','Premium','eNWR'],
    PROFILE_TYPES : ['Farmer', 'Farmer Producer Org (FPO)', 'Agent / Aggregator', 'Exporter', 'Processor / Manufacturer / Service Provider', 'Re-Exporter', 'Importer', 'Authorized Dealer / Distributor']
}

export const SELL_CONST = {
    WEIGHT : 'Stock',
    ACTUALWEIGHT : 'Available Actual Quantity',
    SOURCE : 'Available At Location',
    DESTINATION : 'Preferred Delivery Location',
    DATE_TIME : 'Available From Date',
    ADVANCE : 'Advance Receivable',
    BALANCE : 'Balance Receivable',
    TOTAL_AMT : 'Total Receivable',
    TERMS : 'Requested Terms',
    NOTE : 'This Enquiry is created by seller. This produce is available for sale/bidding. Request you to submit your quote / reply. If seller selects your quote. we will inform you by sms, email and this order will be visible in your order bucket from where you can pay, track, manage.',
    DELIVERY_ADDREE : 'Shipper / Consignor Address'
}

// Buy Enquiry Const
export const BUY_CONST = {
    WEIGHT : 'Required Quantity',
    ACTUALWEIGHT : 'Required Actual Quantity',
    SOURCE : 'Preferred Location To Buy',
    DATE_TIME : 'Required Date',
    ADVANCE : 'Advance Payable',
    BALANCE : 'Balance Payable',
    TOTAL_AMT : 'Total Payable',
    TERMS : 'Commercial Terms',
    NOTE : 'This company wants to purchase this product. If you can provide, please click "REPLY" to submit your quote. If company selects your quote, we will inform you by sms, email and this order will be visible in your order bucket from where you can manage your order.',
    DELIVERY_ADDREE : 'Receiving party / Consignee Address',
}


// Enquiry Status
export class ENQUIRY_STATUS {
    public ENQUIRY_ACTIVE = 1
    public ENQUIRY_APPROVED = 2
    public ENQUIRY_AUTO_CANCEL = 3
    public ENQUIRY_USER_CANCEL = 4
    public ENQUIRY_CONFIRM_OWNER = 5
    public ENQUIRY_CONFIRM_BIDDER = 6
}

// Enquiry Type
export class ENQUIRY_TYPE {
    public BUY_ENQUIRY = 1
    public SELL_ENQUIRY = 2
}

// Order Status
export class ORDER_STATUS {
    public ORDER_INSPECTION_PROCESS_START = 1
    public ORDER_INSPECTION_REQUEST_SUBMIT = 2
    public ORDER_INSPECTION_PROCESS_DONE = 3
    public ORDER_LOADING_PROCESS_START = 4
    public ORDER_LOADING_PROCESS_BUYER_DATE = 5
    public ORDER_LOADING_PROCESS_DONE = 6
    public ORDER_EWAY_PROCESS_START = 7
    public ORDER_EWAY_PROCESS_DONE = 8
    public ORDER_PAYMENT_PROCESS_START = 9
    public ORDER_PAYMENT_PROCESS_DONE = 10
    public ORDER_SHIPPING_PROCESS_START = 11
    public ORDER_SHIPPING_PROCESS_DONE = 12
    public ORDER_INVOICE_PROCESS = 13
    public ORDER_COMPLETE = 14
}

export enum Event {
    CONNECT ='connect',
    DISCONNECT = 'disconnect',
    JOIN = 'join',
    ALERT = 'alert',
    LEFT = 'left'
}