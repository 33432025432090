import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { SocketService } from '../socket.service';
import { ProfileService } from './profile.service';


export interface Response{
    success:boolean,
    message ?: string,
    data ?:any
}


@Injectable({ providedIn: 'root' })
export class LoginService {
    private loggedInUser;
    user = new BehaviorSubject<any>(null);    
    private customertokenExpirationTime: any;
    
    constructor(private http: HttpClient, private router: Router,
        private _profileService:ProfileService,
        private _socket:SocketService) {}
    
    getUser(){
        if(this.loggedInUser){
            return this.loggedInUser;
        }else{
            return null
        }
    }

    customer_login(username, password,phone_code) {
        var gcm_token = localStorage.getItem('gcm_token')
        var json = {username,password,phone_code};
        if(gcm_token){
            json['device_type'] = "web";
            json['device_token'] = gcm_token;
        }

        return this.http
            .post<Response>(`${environment.API_URL}/customer/auth/login`, json)
            .toPromise()
            .then(response => {
                if(response.success){
                    const expiresIn = (response['data']['expiresIn'] ? +response['data']['expiresIn'] : 3600) * 1000;
                    const exprirationData = new Date(expiresIn);
                    this.loggedInUser = {
                        ...response['data'],
                        tokenExpirationTime:
                            new Date().getTime() + +exprirationData,
                    };
                    localStorage.removeItem('adminData');
                    localStorage.setItem('userData',JSON.stringify(this.loggedInUser)                                        
                    );
                    this._socket.join({
                        _id:this.loggedInUser._id,
                        name:this.loggedInUser.fullname
                    })
                    this._profileService.read().then(data=>{
                        this.user.next(data);
                    })
                    // this.user.next(this.loggedInUser);
                    this.customer_autologout(expiresIn);
                }else{
                    return response;
                }
            })

    }

    customer_forgotpassword(username){
        return this.http
        .post(`${environment.API_URL}/customer/auth/forgotpassword`, {username})
        .toPromise()        
    }

    customer_signup(signupData) {
        return this.http
            .post(`${environment.API_URL}/customer/auth/signup`, signupData)
            .toPromise()            
    }
  
    customer_logout() {
        this.loggedInUser = undefined;
        localStorage.removeItem('userData');
        this.user.next(null);
        this._socket.left()
        if (this.customertokenExpirationTime) {
            clearTimeout(this.customertokenExpirationTime);
        }
        this.router.navigate(['/customer/login']);
    }

    customer_autologin() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        if (userData && userData.token) {
            this.loggedInUser = userData;
            this._socket.join({
                _id:this.loggedInUser._id,
                name:this.loggedInUser.fullname
            })
            this._profileService.read().then(data=>{
                this.user.next(data);
            })
            const expirationDuration =
                new Date(userData.tokenExpirationTime).getTime() -
                new Date().getTime();
            this.loggedInUser.tokenExpirationTime = expirationDuration;
            localStorage.removeItem('adminData');
            this.customer_autologout(expirationDuration);
        }else{
            this.user.next(null);
        }     
    }

    customer_autologout(expirationDuration: number) {
        this.customertokenExpirationTime = setTimeout(() => {
            this.customer_logout();
        }, expirationDuration);
    }

   

}
