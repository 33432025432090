import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { LoginService } from '../services/customer/auth.service';

@Injectable()
export class ReqinceptorService implements HttpInterceptor {
    constructor(private loginService: LoginService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): any {
        const user = this.loginService.getUser();
        if(req.url === 'https://restcountries.eu/rest/v2/all'){
            return next.handle(req);
        }
        if(user){
            const modifiedRequest = req.clone({
                headers: req.headers.append('Authorization', 'Bearer ' + user.token)
            });
            const modifiedRequest2 = req.clone({
                headers: modifiedRequest.headers.append('type', 'customer')
            });
            return next.handle(modifiedRequest2);
        } else{
            return next.handle(req);
        }
    }
}