import { Component, OnInit, OnDestroy } from '@angular/core';
import { SocketService } from './services/socket.service';
import { ConfigService } from './services/common/config.service';
import { Title, Meta } from '@angular/platform-browser';
import { LoginService } from './services/customer/auth.service';
import { MessagingService } from './shared/messaging.service';
import { HelperService } from './services/helper.service';
import { PushNotificationService } from './services/push_notification.service';
import { environment } from '../environments/environment';
import {Event} from './config/app.config'

declare var $:any;

@Component({
    selector: 'body',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

    subadmin;

    constructor(
        private titleService: Title,
        private meta: Meta,
        private _customerAuthService: LoginService,
        private _messagingService: MessagingService,
        public _helper: HelperService,
        private _pushNotifications: PushNotificationService,
        private configService: ConfigService,
        private socketService: SocketService,
    ) {
        this._pushNotifications.requestPermission();
    }

    ngOnInit() {

        this._messagingService.requestPermission();
        this._messagingService.receiveMessage()

        this.configService.fetch_subadmin().then(subadmin => {        
            if (subadmin && subadmin !== null) {
                this.subadmin = subadmin
                if (subadmin.app_color) {
                    this._helper.set_site_color({
                        app_color:subadmin.app_color,
                        app_contrast_color:subadmin.app_contrast_color,
                        text_color:subadmin.app_text_color
                    })
                }
                this.titleService.setTitle(subadmin.companyname);
                this.meta.updateTag({ name: 'og:title', content: subadmin.companyname })                
                
            }
        })
        this._customerAuthService.customer_autologin();
        this.initIoConnection();


        this._helper.loadScripts(["https://checkout.razorpay.com/v1/checkout.js", "assets/javascripts/razorpay.js"], () => {   
          console.log("Script Loaded");
        })
    }


    ngAfterViewInit(){
        setTimeout(() => {
            var favIcon: HTMLLinkElement = document.querySelector('#appIcon');
            favIcon.href = environment.IMAGE_URL + this.subadmin.favicon;
        }, 2000);
    }


    private initIoConnection(): void {
        this.socketService.initSocket();

        this.socketService.onEvent(Event.ALERT).subscribe((data) => {
            this._pushNotifications.notify(data)
        });

    }

}

