import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ConfigService } from './common/config.service';

@Injectable({ providedIn: 'root' })
export class SharedService {
    constructor(private configService:ConfigService) { }

    updateUrl(event) {
        // event.target.src = `assets/img/brand/favicon.jpg`;
        event.target.src = environment.IMAGE_URL + this.configService.subadmin.favicon;;
    }

    roundingValue(x:number){
        x.toFixed(2)
    }

    numberWithCommas(x){
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }    

    check_image_file(file) {
        if ( /\.(jpe?g|png|gif)$/i.test(file.files[0].name) === false ) { 
            throw Error('Invalid File');
        }else{
            if(file.files[0].size > 1000000){
                throw Error('Large File');
            }
        }
    }

    check_multiple_image_file(files) {
        for (let index = 0; index < files.length; index++) {
            if ( /\.(jpe?g|png|gif)$/i.test(files[index].name) === false ) { 
                throw Error('Invalid File');
            }else{
                if(files[index].size > 1000000){
                    throw Error('Large File');
                }
            }
        }
        
    }


    check_document_file(file) {
        if ( /\.(jpe?g|png|gif|pdf|doc|docx)$/i.test(file.files[0].name) === false ) { 
            throw Error('Invalid File');
        }else{
            if(file.files[0].size > 1000000){
                throw Error('Large File');
            }
        }
    }

    phone_number_validation(evt) {
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    number_validation(evt) {
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            if (charCode == 46) {
                return true;
            }
            else {
                return false;
            }
        }
        return true;
    }

}