import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'


@Injectable({
    providedIn:'root'
})
export class MessagingService {
    
    currentMessage = new BehaviorSubject(null);

    constructor(private angularFireMessaging: AngularFireMessaging) {}

    requestPermission() {
        this.angularFireMessaging.requestToken.subscribe(token=> {
            if(token){
                localStorage.setItem('gcm_token',token);
            }
        },(err) => {
            console.error('Unable to get permission to notify.', err);
        });
    }

    receiveMessage() {
       this.angularFireMessaging.messages.subscribe(data=>{console.log(data)})
       this.angularFireMessaging.messages.subscribe((message) => { console.log(message); });
    }
}