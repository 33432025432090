import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Partner } from '../../modals/partner.model';

@Injectable({
    providedIn: 'root',
})
export class ConfigService {

    private _subadmin: Partner;
    current_subadmin = new BehaviorSubject<Partner>(null);

    constructor(private http: HttpClient) { }

    get subadmin(){
        return this._subadmin;
    }

    fetch_subadmin() {
        return new Promise<Partner>((resolve, rejects) => {
            resolve(this.subadmin)
        })
    }


    config() {
        // window.location.host
        return this.http.post<Partner>(`${environment.API_URL}/partner/fetch_by_url`, { url: window.location.host })
            .toPromise()
            .then(data => {
                if(data){
                    this.current_subadmin.next(data['data'])
                    this._subadmin = data['data'];
                    return true;
                }else{
                    return null;
                }
            })
    }

    sendMessage(data) {
        return this.http.post(`${environment.API_URL}/partner/contactus_email`, { ...data }).toPromise()
    }

}