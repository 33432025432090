import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as socketIo from 'socket.io-client';
import { environment } from '../../environments/environment';
import {Event} from '../config/app.config'

@Injectable({ providedIn: 'root' })
export class SocketService {
    private socket;
    activeUsers = [];

    constructor() { }


    public initSocket(): void {
        this.socket = socketIo(environment.API_URL);
    }

    public async join(user={_id:1,name:''}) {
        if(this.socket){
            this.socket.emit(Event.JOIN, user);
        }
    }

    public left(): void {
        if(this.socket){
            this.socket.emit(Event.LEFT.toString());
        }
    }

    // public sendMessage(message, sender, receiver): void {
    //     this.socket.emit('message', { message, sender, receiver });
    // }

    // public onMessageReceived(): Observable<any> {
    //     return new Observable<any>(observer => {
    //         this.socket.on('message', (message) => observer.next(message));
    //     });
    // }

    // public onUpdateUser(): Observable<any> {
    //     return new Observable<any>(observer => {
    //         this.socket.on('userupdate', (data: any) => observer.next(data));
    //     });
    // }

    // public onUpdateDriver(): Observable<any> {
    //     return new Observable<any>(observer => {
    //         this.socket.on('driverupdate', (data: any) => observer.next(data));
    //     });
    // }

    // public onUpdateTrip(): Observable<any> {
    //     return new Observable<any>(observer => {
    //         this.socket.on('tripupdate', (data: any) => observer.next(data));
    //     });
    // }

    public onEvent(event: Event): Observable<any> {
        return new Observable<Event>(observer => {
            this.socket.on(event, (data) => {
                if(data){
                    observer.next(data)
                }
            });
        });
    }
}
