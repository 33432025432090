import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers

export const routes: Routes = [
    {
        path: '',
        loadChildren: () =>
        import('./frontend/frontend.module').then(
            m => m.FrontendModule
        ),
    },
    {
        path:'customer',
        loadChildren: () =>
        import('./customer/customer.module').then(
            m => m.CustomerModule
        ),
    },
    { path: '**', redirectTo: '' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
