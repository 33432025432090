import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Customer } from '../../modals/customer.model';
import { Subject } from 'rxjs';
import { saveAs } from 'file-saver';
import { CustomerAddress } from '../../modals/customer_address.model';
import { map } from 'jquery';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ProfileService {

    public changeProfile = new Subject();
    private _customer:Customer = new Customer();

    constructor(private http: HttpClient) { }

    get user(){
        return this._customer;
    }

    read() {
        return this.http
            .post<Customer>(`${environment.API_URL}/customer/profile/read`, {})
            .toPromise()
            .then(response => response['data'])
    }

    balance() {
        return this.http.post(`${environment.API_URL}/customer/profile/balance`, {}).toPromise()
            .then(response => Number(response['data']['balance']))
    }

    update(user) {
        return this.http
            .post(`${environment.API_URL}/customer/profile/update`, user)
            .toPromise()
            .then(response => this.onHandleResponse(response))
    }

    send_balance_invoice({ amount }) {
        return this.http
            .post<Customer>(`${environment.API_URL}/customer/profile/send_balance_invoice`, { amount })
            .toPromise()
            .then(response => this.onHandleResponse(response))
    }

    resend_balance_invoice() {
        return this.http
            .post<Customer>(`${environment.API_URL}/customer/profile/resend_balance_invoice`, {})
            .toPromise()
            .then(response => this.onHandleResponse(response))
    }


    validate_balance() {
        return this.http
            .post<Customer>(`${environment.API_URL}/customer/profile/validate_balance`, {})
            .toPromise()
            .then(response => this.onHandleResponse(response))
    }


    add_balance({ ref_id, ref_type, datetime = null, amount = null }) {
        return this.http
            .post(`${environment.API_URL}/customer/profile/add_balance`, { ref_id, ref_type, datetime, amount })
            .toPromise()
            .then(response => this.onHandleResponse(response))
    }

    cancel_transaction() {
        return this.http
            .post(`${environment.API_URL}/customer/profile/cancel_transaction`, {})
            .toPromise()
            .then(response => this.onHandleResponse(response))
    }


    dashboard_details() {
        return this.http
            .post(`${environment.API_URL}/customer/profile/dashboard_details`, {})
            .toPromise()
            .then(response => response['data'])
    }

    add_address(address: CustomerAddress) {
        return this.http
            .post(`${environment.API_URL}/customer/profile/add_address`, { ...address })
            .toPromise()
            .then(response => this.onHandleResponse(response))
    }

    list_address() {
        return this.http
            .post(`${environment.API_URL}/customer/profile/list_address`, {})
            .toPromise()
            .then(response => response['data'])
    }


    update_address(address: CustomerAddress) {
        return this.http
            .post(`${environment.API_URL}/customer/profile/update_address`, { ...address, address_id: address._id })
            .toPromise()
            .then(response => this.onHandleResponse(response))
    }

    delete_address(address_id) {
        return this.http
            .post(`${environment.API_URL}/customer/profile/delete_address`, { address_id })
            .toPromise()
            .then(response => this.onHandleResponse(response))
    }


    onHandleResponse(response) {
        if (response['success']) {
            this.changeProfile.next();
        }
    }
}
