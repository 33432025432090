import { APP_INITIALIZER, NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { ReqinceptorService } from './interceptor/reqinceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app.routing';
import { SharedModule } from './shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingComponent } from './components/loading/loading.component';
import { ResponseHttpInterceptor } from './interceptor/resinceptor';
import { PushNotificationsModule } from 'ng-push';
import { LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { InitAppService } from './services/initApp.service';
import { environment } from '../environments/environment';

export function appInit(config: InitAppService): (() => Promise<boolean>){
  return (): Promise<boolean> => {
    return new Promise<boolean>((resolve: (a: boolean) => void): void => {
      config._initApp().then(()=>{
			resolve(true)
		})
    });
  };
}

@NgModule({
  imports: [
    CommonModule,
    PushNotificationsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
    NgbModule,
  ],
  declarations: [
    AppComponent,
    LoadingComponent
  ],
  providers: [{
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  }, 
  {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      deps: [
        InitAppService
      ],
      multi: true
  },
  { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
  {
    provide: HTTP_INTERCEPTORS,
    multi: true,
    useClass: ReqinceptorService
  },
  {
    provide: HTTP_INTERCEPTORS,
    multi: true,
    useClass: ResponseHttpInterceptor
  }
],
  bootstrap: [AppComponent]
})
export class AppModule { }
