import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ENQUIRY_STATUS, ENQUIRY_TYPE, ORDER_STATUS,APP_CONFIG, DEFAULT_IMAGE } from '../config/app.config';
import { SharedService } from './shared.service';
import { PopupService } from './popup.service';
import { saveAs } from 'file-saver';
import { ConfigService } from './common/config.service';
import { MediaMatcher } from '@angular/cdk/layout';

declare var swal: any;

export interface Response {
    success: boolean,
    data: any
}

@Injectable({ providedIn: 'root' })
export class HelperService {

    private loadingStatus = new BehaviorSubject<boolean>(null)
    loadingObservable = this.loadingStatus.asObservable()

    public ENQUIRY_TYPE = new ENQUIRY_TYPE()
    public ENQUIRY_STATUS = new ENQUIRY_STATUS()
    public ORDER_STATUS = new ORDER_STATUS()
    public APP_CONFIG = new APP_CONFIG()
    public DEFAULT_IMAGE = DEFAULT_IMAGE;

    constructor(
        public _shared: SharedService,
        public _popup: PopupService,
        public _router: Router,
        public ngZone:NgZone,
        private media:MediaMatcher,
        public _config: ConfigService) {}

    startLoading() {
        this.loadingStatus.next(true)
    }

    stopLoading(timeout = 500) {
        setTimeout(() => {
            this.loadingStatus.next(false)
        }, timeout);
    }

    confirm_dialog(title = "Are You Sure ?", text = "Once Action Performed, It can't Recovered", icon = "warning", confirm_text = 'I Agree') {
        return swal({ title, text, icon, buttons: ['Cancel', confirm_text], dangerMode: true })
    }

    phone_number_validation(evt) {
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57) || charCode === 101) {
            return false;
        }
        return true;
    }

    number_validation(evt) {
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            if (charCode == 46) {
                return true;
            }
            else {
                return false;
            }
        }
        return true;
    }

    space_validation(evt) {
        if (evt.code == "Space" && evt.target.value.length < 1) {
            return false;
        }
        return true
    }


    alert_dialog(title = "Alert", text = "",icon="warning") {
        return swal(title, text, icon);
    }

    set_site_color({app_color = "#1e834d",app_contrast_color = "#1e834d", text_color = "#ffffff"}) {
        document.documentElement.style.setProperty("--theme-bg-dark", app_color)
        document.documentElement.style.setProperty("--theme-bg-light", app_contrast_color)
        document.documentElement.style.setProperty("--theme-text", text_color)
        // document.documentElement.style.setProperty("--theme-bg-light", this.generateLightColor(dark_color, 0.5))
    }

    save_file({ blob, file_name = 'filename' }) {
        saveAs(blob, file_name)
    }

    open_file(blob, type = 'application/pdf') {
        var file = new Blob([blob], { type });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    }

    loadScripts(array, callback) {
        var loader = function (src, handler) {
            var script = document.createElement("script");
            script.src = src;
            script.onload = function () {
                handler();
            }
            var head = document.getElementsByTagName("head")[0];
            (head || document.body).appendChild(script);
        };
        (function run() {
            if (array.length != 0) {
                loader(array.shift(), run);
            } else {
                callback && callback();
            }
        })();
    }

    generateLightColor(hex, lum = 0) {

        hex = String(hex).replace(/[^0-9a-f]/gi, '');
        if (hex.length < 6) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }

        var rgb = "#", c, i;
        for (i = 0; i < 3; i++) {
            c = parseInt(hex.substr(i * 2, 2), 16);
            c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
            rgb += ("00" + c).substr(c.length);
        }
        return rgb;
    }

}


