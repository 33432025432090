import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../../environments/environment";

export class LocationModel {
    latitude: number = 0;
    longitude: number = 0;
    address: string = '';
    city_name: string = '';
    country_name: string = '';
    country_code: string = '';
    state_code: string = '';
    state_name: string = '';
    constructor(){}
}

export interface CurrentLocation {
    city1: string,
    city2: string,
    city3: string,
    country: string,
    country_code : string,
    country_code_2: string,
    city_code: string,
    latitude: any,
    longitude: any,
    address: string
}

@Injectable({ providedIn: 'root' })
export class LocationService {

    private _current_location: LocationModel = new LocationModel();        
    private locationSubject = new BehaviorSubject<LocationModel>(null);
    locationObservable:Observable<LocationModel> = this.locationSubject.asObservable();

    constructor(private _http:HttpClient) { }

    get current_location(){
        return this._current_location;
    }

    set current_location(location){
        this._current_location = location;
        localStorage.setItem('current_location',JSON.stringify(this.current_location));
        this.locationSubject.next(this._current_location);
    }

    set_current_location() {
        return new Promise((resolve,rejects)=>{
            try{
                var location = JSON.parse(localStorage.getItem('current_location'));
                if(location){
                    this.current_location = location;
                    this.locationSubject.next(this.current_location);
                    resolve(true);
                }else{
                    navigator.geolocation.getCurrentPosition((location) => {
                        this.geocoder({latitude:location.coords.latitude,longitude:location.coords.longitude}).then(response=>{
                            this.current_location.latitude = location.coords.latitude;
                            this.current_location.longitude = location.coords.longitude;
                            response['address_components'].forEach(element => {
                                var type = element.types[0]
                                switch (type) {
                                    case 'country':
                                        this.current_location.country_name = element.long_name;
                                        this.current_location.country_code = element.short_name;
                                        break;
                                    case 'administrative_area_level_1':
                                        this.current_location.state_code = element.short_name;
                                        this.current_location.state_name = element.long_name;
                                        break;
                                    case 'administrative_area_level_2':
                                        this.current_location.city_name = element.short_name;
                                        break;
                                    case 'postal_code':
                                        break;
                                    default:
                                        break;
                                }
                            });
                            this.current_location.address = `${this.current_location.city_name}, ${this.current_location.state_name}, ${this.current_location.country_name}`;
                            localStorage.setItem('current_location',JSON.stringify(this.current_location));
                            this.locationSubject.next(this.current_location);
                            resolve(true)
                        })
                    },error=>{
                        resolve(false)
                    })
                }
            }catch(err){
                resolve(false);
            }
            finally{
                resolve(false);
            }
        })
    }


    geocoder({latitude, longitude}) {
        return new Promise((resolve,rejects)=>{
            try{
                this._http.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${environment.GOOGLE_KEY}`,{}).toPromise().then(response=>{
                    if(response['status'] === 'OK' && response['results'].length){
                        resolve(response['results'][0]);
                    }else{
                        resolve(null)
                    }
                })
            }catch(err){
                resolve(null);
            }
        })
    }
    
}


// https://maps.googleapis.com/maps/api/place/autocomplete/json?input=Rajkot&types=address&key=AIzaSyDD0ysJ1Hidhr2x6IqhsKEUNHa0-RGA1Ws
// https://maps.googleapis.com/maps/api/geocode/json?latlng=22,70&key=AIzaSyDD0ysJ1Hidhr2x6IqhsKEUNHa0-RGA1Ws
