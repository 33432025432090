import { Injectable } from '@angular/core';
import { PushNotificationsService } from 'ng-push';

interface PushNotification {
    title?: string
    body?: string
    icon?: string
    tag?: string
    renotify?: boolean
    silent?: boolean
    sound?: string
    noscreen?: boolean
    sticky?: boolean
    dir?: 'auto' | 'ltr' | 'rtl'
    lang?: string
    vibrate?: number[]
}

@Injectable({
    providedIn: 'root'
})

export class PushNotificationService {

    constructor(private _pushNotifications: PushNotificationsService) { }

    requestPermission(){
        this._pushNotifications.requestPermission()
    }

    notify(notification:PushNotification) {
        this._pushNotifications.create(notification.title, notification).subscribe();
    }


}