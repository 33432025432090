export const environment = {
  production: false,
  // API_URL: 'http://localhost:5521',
  // IMAGE_URL: 'http://localhost:5521',

  API_URL: 'https://api.krishitrade.com',
  IMAGE_URL: 'https://api.krishitrade.com',

  // RAZORPAY_PUBLISH_KEY: "rzp_live_o7EtcBUt4l28ph",
  RAZORPAY_PUBLISH_KEY: "rzp_test_z7WuPcfHOVmSm0",
  GOOGLE_KEY: 'AIzaSyD0Fm28rIE4VeAPa6sBlVgXNUYCF9ZhzTk',
  firebaseConfig: {
    apiKey: "AIzaSyDO-xnDp_GOJEsUwsEgiL56vhjEOMI44BI",
    authDomain: "krishitrade-b2b.firebaseapp.com",
    projectId: "krishitrade-b2b",
    storageBucket: "krishitrade-b2b.appspot.com",
    messagingSenderId: "615968703447",
    appId: "1:615968703447:web:f23b3761729e5d947e8192",
    measurementId: "G-MG8R9GHXBN"
  }

};
